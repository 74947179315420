import { Component } from '@angular/core';

@Component({
  selector: 'app-curves-animation',
  templateUrl: './curves-animation.component.html',
  styleUrls: ['./curves-animation.component.scss']
})
export class CurvesAnimationComponent {

}
