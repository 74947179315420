<div *ngIf="isLoading" class="w-100 d-flex justify-content-center">
    <div class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
