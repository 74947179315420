<form [formGroup]="contactForm" (ngSubmit)="sendMessage()">
    <div [ngClass]="deviceDetector.isMobile() ? 'w-100 mb-5' : 'form-container mx-3 p-3'">
        <div [ngClass]="deviceDetector.isMobile() ? 'form-row-mobile' : 'form-row'">
            <label for="name">
                <input id="name"
                    type="name"
                    class="neu"
                    placeholder="Name"
                    formControlName="name"
                    [ngClass]="{'neu-error' : contactForm.controls.name.invalid && contactForm.controls.name.touched}"
                    required>
            </label>
            <label for="email">
                <input id="email"
                    type="email"
                    class="neu"
                    placeholder="Email"
                    formControlName="email"
                    [ngClass]="{'neu-error' : contactForm.controls.email.invalid && contactForm.controls.email.touched}"
                    required>
            </label>
        </div>
        <div [ngClass]="deviceDetector.isMobile() ? 'form-row-mobile' : 'form-row'">
            <label for="message">
                <textarea id="message"
                    type="text"
                    class="neu mr-2 mb-3"
                    placeholder="Message"
                    rows="5"
                    formControlName="message"
                    [ngClass]="{'neu-error' : contactForm.controls.message.invalid && contactForm.controls.message.touched}"
                    required></textarea>
            </label>
        </div>

        <div [ngClass]="deviceDetector.isMobile() ? 'd-flex justify-content-center' : 'd-flex flex-row-reverse mr-1'">
            <button [disabled]="contactForm.invalid" class="neu primary" type="submit" (click)="executeRecaptchaV3()">Send</button>
        </div>
    </div>
</form>
