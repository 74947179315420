<div *ngIf="!isMobile" class="w-100 h-100">
    <app-curves-animation></app-curves-animation>
    <div  class="main-container d-flex justify-content-between align-items-stretch">
        <app-navigation-block></app-navigation-block>
        <div class="glass w-100 router-wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<div *ngIf="isMobile" class="glass w-100 h-100 mobile-container">
    <app-mobile-block></app-mobile-block>
</div>
