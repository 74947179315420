export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyAXqRmYeaR1CGvLDUQcrLBPaedk0BcYw00",
        authDomain: "ej-my-portfolio.firebaseapp.com",
        databaseURL: "https://ej-my-portfolio-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "ej-my-portfolio",
        storageBucket: "ej-my-portfolio.appspot.com",
        messagingSenderId: "555245528413",
        appId: "1:555245528413:web:1576706645176b76ebb4b4",
        measurementId: "G-554YQ17R54"
    },
    recaptcha: {
        siteKey: '6Lc7Kb0gAAAAADCltaPuxHM7O9spYfXPUsVXD9nK'
    },
    adSense: {
        client: 'ca-pub-3153004667560334',
        adSlots: {
            pageBottom: 8849600453 
        },
        show: true
    },
    elasticEmail: {
        username: 'e2businesslt@gmail.com',
        password: 'D0ED13D1D505E66710A2D4CB5A1C71BCDBF3',
        server: 'smtp.elasticemail.com',
        port: '2525',
        to: 'edwardasas@gmail.com'
    }
};
