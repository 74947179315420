<div class="w-100 d-flex flex-column" [ngClass]="{'h-100': !deviceDetector.isMobile()}">
    <div class="text-block" [ngClass]="deviceDetector.isMobile() ? 'text-block-mobile' : 'text-block'">

        <app-loader *ngIf="loading"></app-loader>
        
        <div *ngIf="!loading">
            <div class="title-line">
                <span>{{title}}</span><span id="name"><b>{{name}}</b></span>
            </div>
            <br>
            <div class="text-paragraph">
                <span>{{info}}</span>
                <span id="company">
                    <a [href]="company?.url" target="_blank">{{company?.name}}</a>.
                </span>
            </div>
            <div class="text-paragraph">
                <span>{{description}}</span>
            </div>
        </div>
    </div>
    <div *ngIf="!deviceDetector.isMobile()" class="mt-auto d-flex justify-content-around navigation-buttons">
        <button class="glass" [routerLink]="['/about']">About me</button>
        <button class="glass" [routerLink]="['/skills']">My skills</button>
    </div>

</div>
