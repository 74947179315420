export enum Collection {
    SOCIAL = 'social',
    PROFILE = 'profile',
    HOME_TAB_DATA = 'tab-data-home',
    SKILLS_TAB_DATA = 'tab-data-skills',
    ABOUT_TAB_DATA = 'tab-data-about',
    EXPERIENCE_TAB_DATA = 'tab-experience',

    BASICS = 'basics',
    STORIES = 'stories',
    SKILLS = 'skills',
    EXPERIENCES = 'experiences'
}
