import { Component } from '@angular/core';

@Component({
  selector: 'app-h-line',
  templateUrl: './h-line.component.html',
  styleUrls: ['./h-line.component.scss']
})
export class HLineComponent {

}
