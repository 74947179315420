<div class="glass h-100 profile-container">
    <div class="glass top-container text-center">
        <app-profile-picture [imageUrl]="picture"></app-profile-picture>
        
        <span *ngIf="loading">
            <div class="loader name-loader skeleton-loader skeleton-loader-fill-sm"></div>
            <div class="loader skeleton-loader skeleton-loader-fill-sm"></div>
        </span>

        <span *ngIf="!loading">
            <h6>{{resolveFullName(data)}}</h6>
            <div class="info-text">{{data?.position}}</div>
        </span>
    </div>

    <div>
        <app-navigation-buttons></app-navigation-buttons>
    </div>
    
    <div class="w-100 social-links-wrapper">
        <app-social-links-bar [data]="(socialLinks$ | async)"></app-social-links-bar>
    </div>
</div>
